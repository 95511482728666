import React, { Component, Fragment } from "react";
import styled from "styled-components";
import AppConfig from "../Config";
import * as Icon from "../Components/Icon";
import * as Widget from "./Widget";

class RadioSelectorGroupField extends Component {
  render() {
    const { options, value, onChange } = this.props;

    return (
      <Fragment>
        {options.map(option => (
          <Widget.CheckboxField
            key={option.value}
            onClick={() => onChange(option.value)}
            extraCss={option.extraCss || ""}
          >
            <div className="box-wrapper">
              {value === option.value ? (
                <Icon.RadioButtonChecked
                  color={`${AppConfig["global"].themeColor}`}
                  size={20}
                />
              ) : (
                <Icon.RadioButtonUnchecked
                  color={`${AppConfig["global"].themeColor}`}
                  size={20}
                />
              )}
            </div>
            <p className="label-text">{option.label}</p>
          </Widget.CheckboxField>
        ))}
      </Fragment>
    );
  }
}

export default RadioSelectorGroupField;
