import React, { Component } from "react";
import styled from "styled-components";
import * as Widget from "./Widget";

const Wrapper = styled.div`
  margin: 20px 0;

  & > p {
    font-size: 14px;
    color: #575757;
    margin-bottom: 10px;

    & > span {
      color: #f92b2b;
    }
  }
`;

class TextInputField extends Component {
  render() {
    const { label, isRequired, value, onChange } = this.props;

    return (
      <Wrapper>
        <p>
          {label}
          {isRequired && <span>*</span>}
        </p>
        <Widget.Input
          type="text"
          value={value}
          onChange={e => onChange(e.target.value)}
        />
      </Wrapper>
    );
  }
}

export default TextInputField;
