import React, { Fragment } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import TextInputField from "../../Components/TextInputField";
import { withPage } from "../../Page";
import AppConfig from "../../Config";
import Selectors from "../../Selectors";
import ActionCreator from "../../ActionCreator";
import * as Icon from "../../Components/Icon";
import * as Widget from "../../Components/Widget";
import OrderStatusBar from "../../Components/OrderStatusBar";
import OrderBasket from "../../Components/OrderBasket";
import CartConfig from "../../Components/CartConfig";
import OrderPaymentInfo from "../../Components/OrderPaymentInfo";
import OrderBuyerInfo from "../../Components/OrderBuyerInfo";
import PaymentButton from "../../Components/PaymentButton";

const UI_STATE = {
  CART: "CART",
  PAYMENT: "PAYMENT"
};

const UI_STATE_TO_STEP_MAP = {
  CART: 1,
  PAYMENT: 2
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: stretch;

  & > .container {
    flex: 1;
    margin: 0 auto;
    padding-top: 80px;
    padding-bottom: 60px;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 1200px) {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: flex-start;
    }

    & > .left-section {
      width: 621px;
      margin-right: 100px;
      @media screen and (max-width: 1200px) {
        flex: 1;
        margin-right: 0;
      }
      @media screen and (max-width: 767px) {
        width: 335px;
      }
      @media screen and (max-width: 374px) {
        width: 300px;
      }

      & > .info {
        width: 100%;
        height: 100%;

        & > .title {
          margin-top: 52px;
          font-size: 16px;
          color: #575757;

          & > span {
            font-size: 14px;
            color: #f92b2b;
            margin-left: 10px;
          }
        }

        & > .hint {
          font-size: 14px;
          color: #a4a4a4;
        }

        & > .hint-wrapper {
          margin-top: 30px;
          margin-bottom: 46px;
          padding: 30px;
          border: 1px solid #dadada;
          @media screen and (max-width: 767px) {
            padding: 20px;
          }

          & > p {
            font-size: 14px;
            color: ${AppConfig["global"].themeColor};
          }
        }
      }
    }

    & > .right-section {
      width: 400px;

      @media screen and (max-width: 1200px) {
        width: 620px;
        margin-bottom: 60px;
      }
      @media screen and (max-width: 767px) {
        width: 335px;
      }
      @media screen and (max-width: 374px) {
        width: 300px;
      }
    }
  }

  & > .footer {
    height: 120px;
    display: flex;
    background-color: ${AppConfig["global"].themeColor};
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0 160px;
    @media screen and (max-width: 1200px) {
      padding: 0 65px;
    }
    @media screen and (max-width: 767px) {
      padding: 0 20px;
    }

    & > button {
      width: 160px;
      height: 40px;
      border: 0;
      font-size: 14px;
      color: ${AppConfig["global"].themeColor};
      background-color: #fff;
      cursor: pointer;
      outline: 0;

      &:disabled {
        background-color: #a9a9a9;
        color: #fff;
        opacity: 0.9;
        cursor: not-allowed;
      }
    }
  }

  ${AppConfig["Templates/CartPage"].css || ""}
`;

class CartPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      uiState: UI_STATE.CART,
      paymentConfig: {
        paymentType: "ecpay"
      },
      order: null
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    let { cartData, localCartDataConfig, appActions, profile } = this.props;
    let { paymentConfig, uiState, loading, order } = this.state;

    if (!profile) {
      return <Wrapper>請先登入！</Wrapper>;
    }

    if (cartData && cartData.items.length === 0) {
      return <Wrapper>購物車為空</Wrapper>;
    }

    return (
      <Wrapper>
        <div className="container">
          <div className="left-section">
            <OrderStatusBar step={UI_STATE_TO_STEP_MAP[uiState]} />
            <div className="info">
              {(() => {
                switch (uiState) {
                  case UI_STATE.CART:
                    return (
                      <>
                        <CartConfig
                          isEditable
                          config={{
                            name: profile.name,
                            phone: profile.phone,
                            ...localCartDataConfig
                          }}
                          updateConfig={data =>
                            appActions.editLocalCartConfig(data)
                          }
                        />
                        <OrderPaymentInfo
                          isEditable
                          config={paymentConfig}
                          updateConfig={data =>
                            this.setState({ paymentConfig: data })
                          }
                        />
                      </>
                    );
                  case UI_STATE.PAYMENT:
                    return (
                      <OrderBuyerInfo order={order} showPaymentBtn={false} />
                    );
                  default:
                    return null;
                }
              })()}
            </div>
          </div>
          <div className="right-section">
            <OrderBasket
              cartData={cartData}
              editMode={uiState === UI_STATE.CART}
              openAsDefault={true}
            />
          </div>
        </div>

        <div className="footer">
          {uiState !== UI_STATE.PAYMENT ? (
            <Widget.SubmitButton
              text={"下一步"}
              loading={loading}
              type="button"
              onClick={this._submit}
              disabled={!this._isAllowNextStep()}
            />
          ) : (
            <PaymentButton
              order={order}
              css={`
                color: ${AppConfig.global.themeColor};
                background-color: white;
              `}
            />
          )}
        </div>
      </Wrapper>
    );
  }

  _submit = () => {
    let { appActions, navActions, profile, localCartDataConfig } = this.props;
    let { paymentConfig, uiState } = this.state;

    if (uiState === UI_STATE.CART) {
      this.setState({ loading: true });
      appActions
        .editCartConfig(localCartDataConfig)
        .then(() => {
          return appActions.createOrder({
            currency: "TWD",
            paymentType: paymentConfig.paymentType,
            title: `user-${profile.id}`
          });
        })
        .then(order => this.setState({ uiState: UI_STATE.PAYMENT, order }))
        .catch(err => 0)
        .then(() => this.setState({ loading: false }));
    }
  };

  _isAllowNextStep = () => {
    let {
      paymentConfig: { paymentType }
    } = this.state;
    let {
      localCartDataConfig: { name, address, email }
    } = this.props;
    return name && address && email && paymentType;
  };
}

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state),
      cartData: Selectors.getCartData(state),
      localCartDataConfig: Selectors.getLocalCartDataConfig(state)
    }),
    ActionCreator
  )(CartPage)
);
